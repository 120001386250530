<template>
  <div>
    <dashboard-page-title
      :showAddBtn="false"
      :showFilter="false"
      :showSearch="true"
      :showExportation="true"
      >الحجوزات</dashboard-page-title
    >
    <main-table :fields="fields" query="&status=active" list_url="doctor/reservations"></main-table>
  </div>
</template>
<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      fields: [
        { label: "Id", key: "id", class: "text-right", sortable: true },
        { label: "اسم العميل", key: "customer_name", class: "text-right" },
        { label: "تكلفة الكشف", key: "price", class: "text-right" },
        {
          label: "تاريخ الزيارة",
          key: "time",
          class: "text-right",
          type: "time",
        },
        {
          label: "التشخيص",
          key: "diagnosis",
          type: "status",
          class: "text-right",
        },
        { label: "تاريخ الإنشاء", key: "created_at", class: "text-right" },
        {
          label: "التحكم",
          key: "actions",
          class: "text-right",
          type: "actions",
          actions: [
            {
              text: "Cancel",
              icon: "ri-close-circle-line",
              color: "danger",
              url: "merchant/products",
              // titleHeader: "منتج",
              // question: "هل متأكد أنك تريد مسح هذا المنتج",
              // textContent: "name",
              // textDeleteButton: "بالتأكيد, مسح",
              // // textCancelButton: "الغاء",
              // showAlert: true,
            },
          ],
        },
      ],
    };
  },
};
</script>
